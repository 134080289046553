
<template>
    <component :is="'div'">

        <!-- Alert: No item found -->
        <b-alert
            variant="danger"
            :show="properData === undefined"
        >
            <h4 class="alert-heading">Error al obtener los datos de la propiedad</h4>
            <div class="alert-body">
                No se encontró ninguna propiedad con este identificador. Verificar en la
                <b-link
                    class="alert-link"
                    :to="{ name: 'propers-list' }"
                >
                    Lista de propiedades
                </b-link>
                para buscar otra propiedad.
            </div>
        </b-alert>

        <b-tabs
            v-if="properData"
            pills
        >
      
            <!-- Tab: General Information -->
            <b-tab>

                <template #title>
                    <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Información general</span>
                </template>
                
                <proper-edit-tab-general-information class="mt-2 pt-75" :proper-data="properData" />
                
            </b-tab>
      
        </b-tabs>
    </component>
</template>

<script>

    import ProperEditTabGeneralInformation from './general-information/ProperEditTabGeneralInformation.vue';
    import { ref, onUnmounted } from '@vue/composition-api';
    import properStoreModule from '../properStoreModule';
    import router from '@/router';
    import store from '@/store';

    export default {
        components: {
            ProperEditTabGeneralInformation
        },
        setup() {
            const properData = ref(null);

            const PROPER_APP_STORE_MODULE_NAME = 'app-proper';

            // REGISTER MODULE
            if (!store.hasModule(PROPER_APP_STORE_MODULE_NAME)) store.registerModule(PROPER_APP_STORE_MODULE_NAME, properStoreModule);

            // UNREGISTER ON LEAVE
            onUnmounted(() => {
                if (store.hasModule(PROPER_APP_STORE_MODULE_NAME)) store.unregisterModule(PROPER_APP_STORE_MODULE_NAME)
            });

            store.dispatch('app-proper/fetchProper', { slug: router.currentRoute.params.slug })
                .then(response => {
                    if (response.data === 'error') {
                        properData.value = undefined
                    } else {
                        properData.value = response.data.proper;
                    }
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        properData.value = undefined
                    }
                });

            return {
                properData
            }
        }
    }

</script>